
import React from 'react';
// import Navbar from "../Navbar/Navbar";
import './Header.css';

const Header = () => {
  return (
    <div className='holder'>
        <header className='header'>
            {/* <Navbar /> */}
            <div className='header-content '>
               
                {/* <SearchForm /> */}
            </div>
        </header>
    </div>
  )
}

export default Header