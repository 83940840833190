
import React from 'react';
import Home from './component/Pages/Home'
import './App.css';
import './index.css'

function App() {
  return (
    <div className="App">
     
     
        <Home />
    
     
    </div>
  );
}

export default App;

