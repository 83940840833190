// export const MOVIE_DATA_REQUEST = 'MOVIE_DATA_REQUEST';
// export const GENRE_LIST = 'GENRE_LIST';
// export const SELECTED_GENRES = 'SELECTED_GENRES';
// export const SUGGESTION = 'SUGGESTION';
// export const FILTERED_MOVIES = 'FILTERED_MOVIES';
// export const SELECTED_MOVIES = 'SELECTED_MOVIES';


// export const GENRE_LIST_REQUEST = 'GENRE_LIST_REQUEST';
// export const GENRE_LIST_SUCCESS = 'GENRE_LIST_SUCCESS';
// export const GENRE_LIST_FAILURE = 'GENRE_LIST_FAILURE';

// export const POPULAR_MOVIE_DATA_REQUEST = 'POPULAR_MOVIE_DATA_REQUEST';
// export const POPULAR_MOVIE_DATA_SUCCESS = 'POPULAR_MOVIE_DATA_SUCCESS';
// export const POPULAR_MOVIE_DATA_FAILURE = 'POPULAR_MOVIE_DATA_FAILURE';

// export const SELECTED_GENRES_UPDATE = 'SELECTED_GENRES_UPDATE';

// export const SUGGESTION_REQUEST = 'SUGGESTION_REQUEST';
// export const SUGGESTION_SUCCESS = 'SUGGESTION_SUCCESS';
// export const SUGGESTION_FAILURE = 'SUGGESTION_FAILURE';

// export const FILTERED_MOVIES_UPDATE = 'FILTERED_MOVIES_UPDATE';

// export const SELECTED_MOVIES_UPDATE = 'SELECTED_MOVIES_UPDATE';




export const GENRE_LIST_REQUEST = "GENRE_LIST_REQUEST";
export const GENRE_LIST_SUCCESS = "GENRE_LIST_SUCCESS";
export const GENRE_LIST_FAILURE = "GENRE_LIST_FAILURE";

export const MOVIE_DATA_REQUEST = "MOVIE_DATA_REQUEST";
export const MOVIE_DATA_FAILURE = "MOVIE_DATA_FAILURE";
export const MOVIE_DATA_SUCCESS = "MOVIE_DATA_SUCCESS";

export const SELECTED_GENRES_UPDATE = "SELECTED_GENRES_UPDATE";

export const SUGGESTION_REQUEST = "SUGGESTION_REQUEST";
export const SUGGESTION_SUCCESS = "SUGGESTION_SUCCESS";
export const SUGGESTION_FAILURE = "SUGGESTION_FAILURE";

export const FILTERED_MOVIES_UPDATE = "FILTERED_MOVIES_UPDATE";

export const SELECTED_MOVIES_UPDATE = "SELECTED_MOVIES_UPDATE";

export const MOVIE_INFO_REQUEST = "MOVIE_DISPLAY_REQUEST";
export const MOVIE_INFO_SUCCESS = "MOVIE_DISPLAY_SUCCESS";
export const MOVIE_INFO_FAILURE = "MOVIE_DISPLAY_FAILURE";